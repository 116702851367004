@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@include spb.set-typography;

// Remove default margin for body and html elements
body, html {
  margin: 0;
  height: 100%;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes"
 */
button {
  .mat-icon {
    margin: 0 !important;
  }
}

// Adjust margin for mat-form-field to allow potential label truncation
mat-form-field {
  margin-top: 10px;
}

.sparbanken-button {
  color: #000 !important;
  position: relative;
  background: #FFDF00;
  border-radius: 30px;
  border: 1px solid #FFDF00;
  padding: 5px 35px;
  font: 600 16px / 27px "Titillium Web", sans-serif;
  transition: all 0.2s;

  // Style for disabled sparbanken button
  &:disabled {
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    cursor: not-allowed;
  }

  // Hover effect for enabled sparbanken button
  &:not(:disabled):hover {
    background-color: #FFEA80;
    border-color: #FFEA80;
    cursor: pointer;
  }
}

.white-background {
  background-color: #FFF;
  border: 1px solid #808080FF;

  // Hover effect for enabled sparbanken button
  &:not(:disabled):hover {
    background-color: #F5F5F5;
    border: 1px solid black;
    cursor: pointer;
  }
}

// Flex container for aligning items
.text-with-info-holder {
  display: flex;
  align-items: center;
}

// Message container with responsive design
.text-with-info-message {
  margin-bottom: 32px !important;
}

// Radio group in for example risk-level.component and sustainability.component
.radio-group {
  display: flex;
  flex-direction: row;
  gap: 30px;

  &.small-screen {
    flex-direction: column;
  }


  .radio-button input[type="radio"] {
    display: none;
  }

  .radio-button .radio-label {
    height: 30px;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    width: 130px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #9E9E9E;
    font-size: 14px;
    align-items: center;

    img {
      width: 35px;
      margin-right: 7px;
    }
  }

  .option-holder {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .info-text {
      max-width: 240px;
      padding-left: 16px;
      margin-top: 6px;
    }
  }

  .radio-button input[type="radio"]:checked + .radio-label {
    background-color: var.$spb-yellow;
    border: 1px solid var.$spb-yellow;
    font-weight: 600;
  }
}

.max-width {
  max-width: 850px;
}

.max-width.small-screen {
  // width: 300px;
}

.disclaimer {
  margin-top: 20px !important;
  font-size: 12px;
  line-height: 1.3;
}

.special {
  font-size: 14px;
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

.buttons-holder {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 12px;
  margin: 25px 0 12px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}